import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import cx from 'clsx';
import { colors } from '../../../packages/colors';
import { Icons } from '../../../packages/icons';
import { inputSize } from '../../../packages/ui/atoms/input';
import { MessageInputField } from '../../../packages/ui/templates/message-input-field';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { fetchChatRecommendations } from '../../../services/conversation/conversation.service';
import { profileActionCreators } from '../../../redux/modules/profile';
import { getProfile } from '../../../redux/modules/profile/selectors';
import { Avatar, avatarType } from '../../../packages/ui/atoms/avatar';
import { Badge, badgeType } from '../../../packages/ui/atoms/badge';
import { iconBtnPosition, iconBtnType, IconButton } from '../../../packages/ui/molecules/icon-button';
import { btnSize, btnType, Button } from '../../../packages/ui/atoms/button';
import { Text } from '../../../packages/ui/atoms/typography';
import { Menu } from '../../../packages/ui/molecules/menu';
import { getAuth } from '../../../redux/modules/auth/selectors';
import { useStyles } from './MessageInput.styles';
import RecommendationBox from './recommendation-box/RecommendationBox';
import Typography from '../../../components/v2/Typography';

const MessageInput = ({
  lastMessageId,
  username,
  onSubmit,
  channelMembers,
  currentUserId,
  selectedContact,
  selectedUser,
  setSelectedUser,
  inputRef2,
  onOpenAppointment,
  onOpenCaseNotes,
  showInputHeader,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const fileRef = useRef(null);

  const { meta } = useSelector(getAuth);
  const { providers } = useSelector(getProfile);

  const [msg, setMsg] = useState('');
  const [acceptFile, setAcceptFile] = useState('');
  const [fileWithData, setFileWithData] = useState({ hasFile: false, file: File });
  const [userList, setUserList] = useState(providers);
  const [userListCopy, setUserListCopy] = useState(providers);
  const [showUserList, setShowUserList] = useState(false);
  const [userTagClicked, setUserTagClicked] = useState(false);
  const [cursorWordIndex, setCursorWordIndex] = useState(0);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [recommendation, setRecommendation] = useState(null);
  const [magicReplyActive, setMagicReplyActive] = useState(false);

  useEffect(() => {
    if (selectedContact && lastMessageId && meta.authority === 'CARE_NAVIGATOR') {
      void getChatRecommendations();
    }
  }, [selectedContact, lastMessageId]);

  useEffect(() => {
    void fetchProviderDetail();
  }, []);

  useEffect(() => {
    if (channelMembers?.length !== 0) {
      const uniqueProviders = channelMembers?.filter(item => item.userId !== currentUserId);
      const filteredProviders = providers.filter(member2 => {
        return uniqueProviders?.some(member1 => member1.userId === member2.providerId);
      });
      setUserList(filteredProviders);
      setUserListCopy(filteredProviders);
    }
  }, [channelMembers, providers]);

  useEffect(() => {
    if (userListCopy && !showUserList) {
      const selectedUserIds = selectedUser?.map(item => item?.providerId);
      const filteredProviders = userListCopy?.filter(item => !selectedUserIds?.includes(item.id));
      setUserList(filteredProviders);
    }
  }, [selectedUser, userListCopy, showUserList]);

  const handleSubmit = () => {
    let fileData = fileWithData;
    if (fileWithData.hasFile && msg !== '') {
      fileData = {
        ...fileWithData,
        textMessage: msg,
      };
    }
    onSubmit(!fileWithData.hasFile ? msg : fileData);
    setFileWithData({ hasFile: false, file: null });
    setMsg('');
    setCursorWordIndex(0);
  };

  const getChatRecommendations = async () => {
    try {
      const pathParams = { messageId: lastMessageId };
      const queryParams = {
        channelUrl: selectedContact.channelUrl,
        memberId: selectedContact.id ?? selectedContact.connectionId,
      };
      const response = await fetchChatRecommendations(pathParams, queryParams);
      if (response.data.success) {
        const { status, recommendationType } = response.data.state;
        if (status === 'IN_PROGRESS') {
          // Poll the API again after 5 seconds
          setTimeout(() => {
            void getChatRecommendations();
          }, 5000);
        } else if (status === 'COMPLETED' && recommendationType !== 'RESPONSE') {
          // Handle the completed state
          setRecommendation(response.data?.state);
        }
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const fetchProviderDetail = async () => {
    const queryParams = {
      searchQuery: '',
      pageNumber: 1,
      pageSize: 300,
      orderBy: '',
      sortBy: '',
      careNavigator: true,
    };
    await dispatch(profileActionCreators.fetchProviders(queryParams));
  };

  const findWordIndex = (sentence, cursorIndex) => {
    const words = sentence.split(' ');
    let charCount = 0;
    for (let i = 0; i < words.length; i++) {
      charCount += Number(words[i].length) + 1;
      if (charCount > cursorIndex) {
        return i;
      }
    }
    return words.length;
  };

  const onChangeMsg = e => {
    if (location.pathname === '/provider/chats') {
      const text = e.target.value;
      const cursorIndex = e.target.selectionStart;
      const wordIndex = findWordIndex(text, cursorIndex);
      const lastWord = text.split(' ');
      const usernames = text
        .split(' ')
        .filter(str => str.startsWith('@'))
        .map(str => str.replace('@', '').replaceAll('_', ' '));

      const newSelectedUser = selectedUser.filter(item => usernames.includes(item.fullName));
      setSelectedUser(newSelectedUser);
      setMsg(e.target.value);
      setCursorWordIndex(wordIndex);

      if (selectedContact?.type !== 'PATIENT') {
        if (lastWord[wordIndex].startsWith('@')) {
          setShowUserList(true);
          const searchTerm = lastWord[wordIndex].substring(1).toLowerCase();

          const searchedUserList = userListCopy.filter(
            user =>
              !selectedUser.some(taggedUser => taggedUser.id === user.id) &&
              user.fullName.toLowerCase().includes(searchTerm),
          );

          setUserList(searchedUserList);
        } else {
          setShowUserList(false);
          setUserList(userListCopy);
        }
      }
      if (msg.length < 2) {
        setSelectedUser([]);
      }
    } else {
      setMsg(e.target.value);
    }
  };

  const handleKeyDown = e => {
    if (userList.length === 0) return;
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : userList.length - 1));
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prevIndex => (prevIndex < userList.length - 1 ? prevIndex + 1 : 0));
    } else if (e.key === 'Enter') {
      e.preventDefault();
      const newText = msg.split(' ');
      if (newText[cursorWordIndex].startsWith('@')) {
        const userSelected = userList[focusedIndex];
        const selectedPIDs = selectedUser?.map(provider => provider.providerId);
        if (userSelected === undefined) {
          void handleSubmit();
          setShowUserList(false);
          setFocusedIndex(-1);
        } else if (!selectedPIDs.includes(userSelected?.providerId)) {
          if (!userTagClicked) {
            newText[cursorWordIndex] = `@${userSelected?.fullName.replaceAll(' ', '_')} `;
            const modifiedText = newText.join(' ');
            setMsg(modifiedText);
            setSelectedUser([...selectedUser, userSelected]);
          } else {
            void handleSubmit();
            setShowUserList(false);
            setFocusedIndex(-1);
          }
          setUserTagClicked(false);
          setShowUserList(false);
          setFocusedIndex(-1);
          inputRef2.current.focus();
        } else if (e.key === 'Enter' && !e.shiftKey) {
          void handleSubmit();
          setShowUserList(false);
          setFocusedIndex(-1);
        }
      } else if (e.key === 'Enter' && !e.shiftKey) {
        void handleSubmit();
        setShowUserList(false);
        setFocusedIndex(-1);
      }
    }
  };

  const onOpenFile = fileType => () => {
    setAcceptFile(fileType);
    // handleClearMessageInput();
    setTimeout(() => {
      fileRef.current?.click();
    }, 100);
  };

  const onChangeFile = e => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    setFileWithData({ hasFile: true, file });
    // reset the value
    e.target.value = '';
  };

  const onAddMentionClick = () => {
    const newText = msg.split(' ');
    if (newText[cursorWordIndex] === '') {
      newText[cursorWordIndex] = '@';
      const modifiedText = newText.join(' ');
      setMsg(modifiedText);
      setShowUserList(true);
    } else if (newText[cursorWordIndex] !== '') {
      newText[cursorWordIndex] = `${newText[cursorWordIndex]} @`;
      const modifiedText = newText.join(' ');
      setCursorWordIndex(cursorWordIndex + 1);
      setMsg(modifiedText);
      setShowUserList(true);
    }
  };

  const handleClearMessageInput = () => {
    setMsg('');
    setFileWithData({ hasFile: false, file: null });
    setCursorWordIndex(0);
    setShowUserList(false);
    setFocusedIndex(-1);
  };

  const removeAttachment = () => {
    setFileWithData({ hasFile: false, file: null });
    setAcceptFile('');
    setMsg('');
  };

  const renderMenuItem = (lable, icon, fileType) => (
    <div className={classes.dropdownItem} onClick={onOpenFile(fileType)}>
      <Icons color={colors.primary500} glyph={icon} />
      {lable}
    </div>
  );

  const handleUserSelect = user => {
    const newText = msg.split(' ');
    const selectedPIDs = selectedUser?.map(provider => provider.providerId);
    if (!selectedPIDs.includes(user.providerId)) {
      newText[cursorWordIndex] = `@${user.fullName.replaceAll(' ', '_')} `;
      const modifiedText = newText.join(' ');
      setMsg(modifiedText);
      setSelectedUser([...selectedUser, user]);
      setUserTagClicked(true);
      setShowUserList(false);
      inputRef2.current.focus();
    }
  };

  const handleRecommendationAction = type => {
    if (type === 'CASE_NOTE') {
      // Handle CASE_NOTE logic
      onOpenCaseNotes();
    } else if (type === 'APPOINTMENT') {
      // Handle Appointment logic
      onOpenAppointment(recommendation?.actionMetaData?.payload ?? null);
    }
  };

  return (
    <>
      <input
        style={{ display: 'none' }}
        ref={fileRef}
        type="file"
        accept={acceptFile}
        onChange={onChangeFile}
      />
      {recommendation !== undefined && (
        <RecommendationBox
          recommendation={recommendation}
          member={channelMembers?.find(user => user.userId === recommendation?.memberId)}
          onClose={() => setRecommendation(null)}
          onAction={handleRecommendationAction}
        />
      )}
      {showUserList && (
        <>
          {userList.length > 0 && (
            <>
              <ul className={classes.mentionUserList}>
                {userList.map((user, index) => (
                  <li
                    key={user.id}
                    className={clsx(
                      classes.mentionUserListData,
                      index === focusedIndex ? classes.focused : '',
                    )}
                    onMouseEnter={() => setFocusedIndex(index)}
                    onMouseLeave={() => setFocusedIndex(-1)}
                  >
                    <a onClick={() => handleUserSelect(user)}>
                      <div className={classes.member}>
                        <Avatar
                          size={40}
                          variant={avatarType.CIRCLE}
                          src={user.profileImage}
                          name={user.fullName}
                        />
                        <div className={classes.info}>
                          <div className={classes.userName}>{user.fullName}</div>
                          <Badge
                            className={clsx(classes.badge, user.designation?.split(' ').join(''))}
                            variant={badgeType.FILLED}
                          >
                            {user.designation ?? 'Care Navigator'}
                          </Badge>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      )}
      {showInputHeader && (
        <Box padding={2} className={classes.inputHeaderFooter}>
          <Box className={classes.inputHeaderLeft}>
            <Icons
              glyph={magicReplyActive ? 'wand-outlined' : 'chat'}
              color={!magicReplyActive ? colors.neutral900 : colors.primary500}
            />
            <Text
              color={!magicReplyActive ? colors.neutral900 : colors.primary500}
              className="inputHeaderText"
            >
              {magicReplyActive && 'Magic '}Reply
            </Text>
          </Box>
          <Box>
            <IconButton
              size={btnSize.SMALL}
              icon={magicReplyActive ? 'alert-outlined' : 'wand-outlined'}
              iconPosition={iconBtnPosition.RIGHT}
              onClick={() => setMagicReplyActive(!magicReplyActive)}
              className={clsx(classes.inputHeaderRight, magicReplyActive && classes.deactive)}
            >
              {magicReplyActive ? 'Deactivate Magic' : 'Activate Magic'}
            </IconButton>
          </Box>
        </Box>
      )}
      <Box
        padding={2}
        className={cx(classes.inputBody, {
          [classes.inputBodyBackground]: showInputHeader,
        })}
      >
        <MessageInputField
          // disabled={fileWithData.hasFile}
          placeholder={
            location.pathname === '/provider/chats' ||
            location.pathname.includes('/provider/chats/chats-with-members/') ||
            location.pathname.includes('/provider/chats/chats-with-providers/') ||
            location.pathname.includes('/provider/chats/chats-with-groups/')
              ? `Message ${username}`
              : 'Type your message here...'
          }
          size={inputSize.S}
          username={username}
          onSubmit={handleSubmit}
          value={msg}
          onChange={onChangeMsg}
          onKeyDown={handleKeyDown}
          inputRef={inputRef2}
        />
        {fileWithData.hasFile && (
          <Box sx={{ display: 'flex', padding: '0px 12px' }}>
            <Typography
              sx={{
                display: 'flex',
                fontSize: 12,
                lineHeight: '16px',
                color: colors.neutral900,
              }}
            >
              <span className={classes.fileAttachmentText}>{fileWithData.file.name}</span>{' '}
              <span style={{ fontWeight: 500 }}>attached •</span>
            </Typography>
            <Button
              variant={btnType.TEXT}
              className={classes.removeAttachmentButton}
              onClick={removeAttachment}
            >
              Remove
            </Button>
          </Box>
        )}
      </Box>
      <Box padding={2} className={classes.inputHeaderFooter}>
        <Box display="flex">
          <IconButton
            size={btnSize.SMALL}
            icon="delete-outlined-2"
            className={classes.messageInputIcons}
            onClick={handleClearMessageInput}
          >
            Clear
          </IconButton>
          <Menu
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            items={[
              { label: renderMenuItem('Add Image', 'add-image-outlined', 'image/*') },
              { label: renderMenuItem('Add Video', 'play-outlined', 'video/*') },
            ]}
            className={classes.menuItemsWrapper}
          >
            <IconButton size={btnSize.SMALL} icon="attach" className={classes.messageInputIcons}>
              Attach File
            </IconButton>
          </Menu>
          {location.pathname === '/provider/chats' && (
            <IconButton
              size={btnSize.SMALL}
              icon="mentions-icon"
              className={classes.messageInputIcons}
              onClick={onAddMentionClick}
            >
              Tag
            </IconButton>
          )}
        </Box>
        <Box>
          <IconButton
            disabled={!(msg?.trim().length > 0 || fileWithData.hasFile)}
            size={btnSize.SMALL}
            icon="send-filled"
            className={clsx(
              classes.sendBtn,
              !(msg?.trim().length > 0 || fileWithData.hasFile) && classes.deactiveBtn,
            )}
            iconPosition={iconBtnPosition.RIGHT}
            variant={iconBtnType.OUTLINE}
            onClick={handleSubmit}
          >
            Send
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export { MessageInput };
